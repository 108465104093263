
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "QuestionnaireNavigationBlock",
  data() {
    return {
      showNavigation: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.showNavigation = window.scrollY > 500;
    },
    scroll() {
      this.jq("html, body").stop().animate({ scrollTop: 0 }, 500);
    },
  },
});
